import React from "react"
import SEO from "../seo"

import "./index.scss"

const ContactPage = () => {
  return (
    <div className="contact-page">
      <SEO title="Contact us" />

      <div className="container">
        <h1 className="contact-page__title">Contact us</h1>

        <h1>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:info@maxvr.co.nz"
          >
            <span>Email</span> info@maxvr.co.nz
          </a>
        </h1>
        <h1>
          <span>Instagram</span>{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/maxvirtualreality/"
          >
            @maxvirtualreality
          </a>
        </h1>

        <h1>
          <span>Facebook</span>{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/MaxVirtualReality"
          >
            /maxvirtualreality
          </a>
        </h1>
        <h1>
          <span>Youtube</span>{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCD8R-04ilqMuyw-KHmGDbgQ"
          >
            /maxvirtualreality
          </a>
        </h1>
      </div>
    </div>
  )
}

export default ContactPage
